<template>
	<!-- Projects start -->
	<section class="pt-20 sm:pt-28">
		<!-- Projects grid title start -->
		<div class="text-center">
			<p
				class="text-2xl sm:text-5xl font-semibold mb-3 text-ternary-dark dark:text-ternary-light"
			>
				Projects Portfolio
			</p>
			<p class="text-md sm:text-xl text-gray-500 dark:text-ternary-light">
				Some of the projects I have worked on
			</p>
		</div>
		<!-- Projects grid title end -->

		<!-- Projects grid start -->
		<div class="grid grid-cols-1 sm:grid-cols-3 mt-16 sm:gap-10">
			<router-link
				v-for="project in projects"
				:key="project.id"
				:to="project.path"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
				aria-label="Project"
			>
				<div>
					<img
						:src="project.img"
						:alt="project.title"
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						{{ project.title }}
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>{{ project.category }}</span
					>
				</div>
			</router-link>
		</div>
		<!-- Projects grid end -->

		<!-- Load more projects button start -->
		<div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/projects"
				class="flex items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl font-medium"
				aria-label="More Projects"

				>More Projects (currently being added)</router-link
			>
		</div>
		<!-- Load more projects button end -->
	</section>
	<!-- Projects end -->
</template>

<script>
import feather from 'feather-icons';

export default {
	name: 'Projects',
	data() {
		return {
			projects: [
				{
					id: 1,
					title: 'UECST Website',
					category: 'Web Application',
					img: require('../../assets/images/uecst.jpg'),
					path: '/projects/uecst',
				},
				{
					id: 2,
					title: 'MeanSquared Website',
					category: 'Web Application',
					img: require('../../assets/images/msq.jpg'),
					path: '/projects/msq',
				},
				{
					id: 3,
					title: 'Dreaker',
					category: 'Game',
					img: require('../../assets/images/dreaker.jpg'),
					path: '/projects/dreaker',
				},
				{
					id: 4,
					title: 'Ice Cave',
					category: 'Game',
					img: require('../../assets/images/ice-cave.jpg'),
					path: '/projects/ice-cave',
				},
				// {
				// 	id: 5,
				// 	title: 'Codepen Projects',
				// 	category: 'Web Application',
				// 	img: require('../../assets/images/msq.jpg'),
				// 	path: '/projects/codepen',
				// },
				// {
				// 	id: 6,
				// 	title: 'Circlab',
				// 	category: 'Desktop Application',
				// 	img: require('../../assets/images/msq.jpg'),
				// 	path: '/projects/circlab',
				// },
				
				// {
				// 	id: 7,
				// 	title: 'Military Defense',
				// 	category: '',
				// 	img: require('../../assets/images/mobile-project-1.jpg'),
				// 	path: '/projects/military-defence',
				// },
				// {
				// 	id: 8,
				// 	title: 'Drawer',
				// 	category: 'Desktop Application',
				// 	img: require('../../assets/images/web-project-1.jpg'),
				// 	path: '/projects/drawer',
				// },
				// {
				// 	id: 9,
				// 	title: 'Vector Flow Animation',
				// 	category: 'Application',
				// 	img: require('../../assets/images/web-project-1.jpg'),
				// 	path: '/projects/vector-flow',
				// },
			],
			// publicPath: process.env.BASE_URL,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<style scoped></style>
